import {
  ChatMetricObjType,
  FindWordsRelationsType,
  FindWordsWordListType,
  MetricTypesArrType,
} from '@app/interfaces/pages-types/anatylics-metric.type';
import React, { useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';

import { Button, CircleButton, Input, Select, Toggle, TooltipWrapper } from '@ui';

import { AutoFocusHook } from '@app/utils/helpers';

import { AllStatusProject } from '@app/interfaces/analytics';

import useTranslation from '@app/hooks/use-translation';

import { Controller, useFieldArray, useForm } from 'react-hook-form';

import { ChatMetricTemplatesType } from '@app/interfaces/slices-types/metric-templates.type';

import {
  useCreateMetricTemplateMutation,
  useEditMetricTemplateMutation,
  useGetMetricTemplateGroupsListQuery,
} from '@app/store/api/metric-template.api';

import { useGetAllDictionariesListQuery } from '@app/store/api/dictionaries.api';

import {
  useCreateProjectChatMetricGroupMutation,
  useEditProjectChatMetricGroupMutation,
} from '@app/store/api/chat-api/chat-metric.api';

import { ChatRoleSettingsType } from '@app/interfaces/chat-roles-settings.type';

import { WordListForFindMetric } from '@app/components/words-list-for-find-metric/word-list-for-find-metric';

import { OptionItem } from '@app/components/ui/select/select.type';

import { ColorInput } from '../../color-input/color-input';

import { AddToTemplateModal } from '../../add-to-template-modal/add-to-template-modal';
import { VadModalsChildrenType } from '../../constantsForModals';
import HandleMetricSpecialToggle from '../handle-metric-modal/blocks/handle-metric-special-toggle';
type RelationsFormType = { metric_id: string; type: 'before' | 'after'; value: number };

type FormType = {
  activeColor: string;
  switchValue: boolean;
  name: string;
  c_role: string;
  exclude_begin_messages: number;
  exclude_end_messages: number;
  search_begin_messages?: number;
  search_end_messages?: number;
  select: string;
  wordsListArray: FindWordsWordListType[];
  dictionariesListArray: string[];
  relations?: RelationsFormType[];
};

export const ChatFindWordsModal: React.FC<{
  modalsSetState: (type: string) => void;
  currentTemplate: 'voice' | 'chat';
  copyModal: boolean;
  findWordsMetricsOptions?: OptionItem[];
  dataFields: VadModalsChildrenType[];
  metric: MetricTypesArrType;
  editModal?: boolean;
  metricTemplate?: boolean;
  createFromTemplate?: boolean;
  status?: AllStatusProject | undefined;
  page?: string;
  viewOnly?: boolean;
  chatRoles?: ChatRoleSettingsType[];
  metricSettings?: ChatMetricObjType | undefined;
  metricTemplateSettings?: ChatMetricTemplatesType | undefined;
}> = ({
  dataFields,
  metric,
  editModal,
  chatRoles,
  modalsSetState,
  metricTemplate,
  createFromTemplate,
  status,
  findWordsMetricsOptions: metricOptions,
  page,
  copyModal,
  viewOnly,
  metricTemplateSettings,
  metricSettings,
  currentTemplate,
}) => {
  const { id } = useParams();
  const { t } = useTranslation('pages.metricTemplates');
  const { t: tf } = useTranslation('common.forms');
  const [createTemplateModal, setCreateTemplateModal] = React.useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  //api
  const { data: metricTemplates } = useGetMetricTemplateGroupsListQuery(currentTemplate);
  const { data: dictionaries } = useGetAllDictionariesListQuery();
  const [createMetric] = useCreateProjectChatMetricGroupMutation();
  const [editMetric] = useEditProjectChatMetricGroupMutation();
  const [editMetricTemplate] = useEditMetricTemplateMutation();
  const [createMetricTemplate] = useCreateMetricTemplateMutation();

  const defaultValue = (): {
    exclude_begin_messages: number;
    select: string;
    activeColor: string;
    search_begin_messages: number;
    exclude_end_messages: number;
    search_end_messages: number;
    name: string;
    dictionariesListArray: string[];
    switchValue: boolean;
    relations: RelationsFormType[];
    c_role: string;
    wordsListArray: FindWordsWordListType[];
  } => {
    if (editModal) {
      if (metricTemplate) {
        return {
          wordsListArray: metricTemplateSettings?.settings.wordlist || [],
          dictionariesListArray: metricTemplateSettings?.settings.dictionaries || [],
          activeColor: `#${metricTemplateSettings?.settings.color?.toLowerCase() || ''}`,
          switchValue: metricTemplateSettings?.settings.all_word_forms || false,
          name: `${metricTemplateSettings?.name || ''} ${copyModal ? t('copy') : ''}`.trim(),
          c_role: metricTemplateSettings?.settings.c_role || '',
          exclude_begin_messages: metricTemplateSettings?.settings.exclude_begin_messages || 0,
          exclude_end_messages: metricTemplateSettings?.settings.exclude_end_messages || 0,
          search_begin_messages: metricTemplateSettings?.settings.search_begin_messages || 0,
          search_end_messages: metricTemplateSettings?.settings.search_end_messages || 0,
          select: metricTemplateSettings?.metric_templates_group_id || 'default',
          relations: metricTemplateSettings?.settings.relations?.length
            ? relationsFormatterToFormType(metricTemplateSettings?.settings.relations)
            : [],
        };
      } else {
        return {
          wordsListArray: metricSettings?.settings.wordlist || [],
          dictionariesListArray: metricSettings?.settings.dictionaries || [],
          activeColor: `#${metricSettings?.settings.color?.toLowerCase() || ''}`,
          switchValue: metricSettings?.settings.all_word_forms || false,
          name: metricSettings?.name || '',
          c_role: metricSettings?.settings.c_role || '',
          exclude_begin_messages: metricSettings?.settings.exclude_begin_messages || 0,
          exclude_end_messages: metricSettings?.settings.exclude_end_messages || 0,
          search_begin_messages: metricSettings?.settings.search_begin_messages || 0,
          search_end_messages: metricSettings?.settings.search_end_messages || 0,
          relations: metricSettings?.settings.relations?.length
            ? relationsFormatterToFormType(metricSettings?.settings.relations)
            : [],
          select: '',
        };
      }
    } else {
      if (createFromTemplate) {
        return {
          wordsListArray: metricTemplateSettings?.settings.wordlist || [],
          dictionariesListArray: metricTemplateSettings?.settings.dictionaries || [],
          activeColor: `#${metricTemplateSettings?.settings.color?.toLowerCase() || ''}`,
          switchValue: metricTemplateSettings?.settings.all_word_forms || false,
          name: metricTemplateSettings?.name || '',
          c_role: metricTemplateSettings?.settings.c_role || '',
          exclude_begin_messages: metricTemplateSettings?.settings.exclude_begin_messages || 0,
          exclude_end_messages: metricTemplateSettings?.settings.exclude_end_messages || 0,
          search_begin_messages: metricTemplateSettings?.settings.search_begin_messages || 0,
          search_end_messages: metricTemplateSettings?.settings.search_end_messages || 0,
          select: metricTemplateSettings?.metric_templates_group_id || 'default',
          relations: metricTemplateSettings?.settings.relations?.length
            ? relationsFormatterToFormType(metricTemplateSettings?.settings.relations)
            : [],
        };
      } else
        return {
          activeColor: '',
          switchValue: false,
          name: '',
          c_role: '',
          exclude_begin_messages: 0,
          exclude_end_messages: 0,
          search_begin_messages: 0,
          search_end_messages: 0,
          select: 'default',
          wordsListArray: [],
          dictionariesListArray: [],
          relations: [],
        };
    }
  };
  const findWordsMetricsOptions = editModal
    ? metricTemplateSettings
      ? metricOptions?.filter(
          (option) => option.value !== metricTemplateSettings.metric_template_id,
        )
      : metricOptions?.filter((option) => option.value !== metricSettings?.metric_id)
    : metricOptions;
  const {
    register,
    watch,
    setValue,
    formState: { errors },
    handleSubmit,
    clearErrors,
    control,
  } = useForm<FormType>({ defaultValues: defaultValue() });
  const { append, remove, fields } = useFieldArray({ name: 'relations', control });
  function handleAppendRelation() {
    append({ metric_id: '', type: 'after', value: 1 });
  }
  function handleRemoveRelation(index: number) {
    remove(index);
  }
  const copyToClipboard = useCallback(() => {
    event?.preventDefault();
    let textToCopy: string | undefined = '';
    if (metricSettings && !metricTemplate) {
      textToCopy = metricSettings.metric_id;
    } else if (metricTemplateSettings && metricTemplate) {
      textToCopy = metricTemplateSettings.metric_template_id;
    }
    if (textToCopy) {
      navigator.clipboard.writeText(textToCopy);
    }
  }, [metricSettings, metricTemplateSettings, metricTemplate]);
  const disableFunc = () => {
    if (status === AllStatusProject.PAUSE) {
      return false;
    }
    return status !== AllStatusProject.CREATED;
  };
  const resetAsyncForm = () => {
    modalsSetState(metric.type);
  };
  function formattingRelations(data) {
    return (
      data
        ?.filter(({ metric_id }) => !!metric_id)
        .map((rel) => ({ metric_id: rel.metric_id, [rel.type]: rel.value })) || []
    );
  }
  const onSubmit = (data) => {
    const obj: ChatMetricObjType = {
      name: data.name,
      description: ' ',
      type: metric.type,
      result_value_type: metric.result_value_type,
      settings: {
        c_role: data.c_role,
        all_word_forms: data.switchValue,
        color: data.activeColor?.slice(1).toUpperCase(),
        relations: formattingRelations(data.relations),
        wordlist: data.wordsListArray,
        dictionaries: data.dictionariesListArray,
        exclude_begin_messages: data.exclude_begin_messages,
        exclude_end_messages: data.exclude_end_messages,
        search_begin_messages: data.search_begin_messages,
        search_end_messages: data.search_end_messages,
      },
    };
    console.log(obj, 'priverj');
    if (!editModal) {
      if (metricTemplate) {
        createMetricTemplate({ params: { metric_templates_group_id: watch('select') }, body: obj });
      } else {
        id && createMetric({ params: { project_id: id }, body: obj });
      }
    } else {
      if (metricSettings?.metric_id) {
        id &&
          editMetric({
            params: { project_id: id, metric_id: metricSettings.metric_id },
            body: obj,
          });
      } else if (metricTemplate) {
        if (copyModal) {
          createMetricTemplate({
            params: { metric_templates_group_id: watch('select') },
            body: obj,
          });
        } else {
          metricTemplateSettings?.metric_template_id &&
            editMetricTemplate({
              params: {
                metric_templates_group_id: watch('select'),
                id: metricTemplateSettings?.metric_template_id,
              },
              body: obj,
            });
        }
      }
    }
    resetAsyncForm();
  };
  const getDefaultTemplate = () => {
    return metricTemplates?.find((e) => e.is_default)?.metric_templates_group_id;
  };
  const createMetricTemplateApplyBtn = () => {
    const obj: ChatMetricObjType = {
      name: watch('name') as string,
      description: ' ',
      type: metric.type,
      result_value_type: metric.result_value_type,
      settings: {
        c_role: watch('c_role'),
        all_word_forms: watch('switchValue'),
        color: watch('activeColor').slice(1).toUpperCase(),
        wordlist: watch('wordsListArray'),
        dictionaries: watch('dictionariesListArray'),
        exclude_begin_messages: watch('exclude_begin_messages'),
        exclude_end_messages: watch('exclude_end_messages'),
        search_begin_messages: watch('search_begin_messages'),
        search_end_messages: watch('search_end_messages'),
        relations: formattingRelations(watch('relations')),
      },
    };
    createMetricTemplate({
      params: { metric_templates_group_id: getDefaultTemplate() as string },
      body: obj,
    }).then(() => {
      resetAsyncForm();
      setCreateTemplateModal(false);
    });
    // .catch(() => {
    //   toast.error(t('fill_all'));
    // });
  };

  const clickColor = (event) => {
    setValue('activeColor', event.currentTarget.value);
  };
  const inputFocusRef = AutoFocusHook();

  const getSelectItems = () => {
    return metricTemplates?.map((e) => {
      return { title: e.name, value: e.metric_templates_group_id };
    });
  };
  function changeDictionariesArray(word: string[]) {
    setValue('dictionariesListArray', word);
  }

  function changeWordsArray(word: FindWordsWordListType[]) {
    setValue('wordsListArray', word);
  }
  function relationsFormatterToFormType(relations: FindWordsRelationsType[]): RelationsFormType[] {
    return relations.map((relationMetric) => ({
      metric_id: relationMetric.metric_id,
      type: relationMetric.before ? 'before' : 'after',
      value: relationMetric.before
        ? relationMetric.before
        : relationMetric.after
        ? relationMetric.after
        : 0,
    }));
  }

  function handleClearWordsComponent() {
    setValue('wordsListArray', []);
    setValue('dictionariesListArray', []);
  }

  const relationMetricIsEmptyMarkup = (
    <div className="flex items-center justify-center w-full ">
      <CircleButton
        onClick={handleAppendRelation}
        icon="PlusIcon"
        className="text-action"
        size={36}
      />
    </div>
  );

  return (
    <div className="relative">
      <div className={`w-full justify-between flex gap-[30px]`}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="w-full relative">
            <div className={`flex flex-col gap-[20px] w-[500px]`}>
              <div className="flex items-center justify-between">
                <span className="max-w-[218px] text-0color text-[15px]">{t('metric_group')}</span>
                <span className="mr-[150px]">
                  <b>
                    {metric.group.toUpperCase()} {t('one_metric')}
                  </b>
                </span>
              </div>
              <div>
                {dataFields.map((e) => {
                  return e.inputText.map((e) => {
                    return (
                      <div className="flex items-center justify-between" key={e.id}>
                        <span className="max-w-[218px] text-0color text-[15px]">
                          {e.labelTitle}
                        </span>
                        <div className="w-[280px]">
                          <Input
                            {...inputFocusRef}
                            {...register('name', {
                              required: { value: true, message: tf('required') },
                            })}
                            name="name"
                            placeholder={e.placeholder}
                            error={errors.name?.message}
                          />
                        </div>
                      </div>
                    );
                  });
                })}
              </div>
              {metricTemplate && (
                <div className="flex items-center justify-between">
                  <span className="text-0color text-[15px]">{t('metric_templates_group')}</span>
                  <div className="w-[280px]">
                    <Controller
                      render={() => (
                        <Select
                          error={errors.select?.message}
                          defaultValue={watch('select')}
                          options={[
                            ...(getSelectItems() || []),
                            {
                              title: t('choose') + '...',
                              value: 'default',
                              disabled: true,
                              nullable: true,
                            },
                          ]}
                          onChange={(value) => {
                            setValue('select', value as string);
                            clearErrors('select');
                          }}
                        />
                      )}
                      name={'select'}
                      rules={{ validate: (value) => value !== 'default' || tf('required') }}
                      control={control}
                    />
                  </div>
                </div>
              )}
              <div className="flex items-center justify-between mr-[140px] relative">
                <span className="max-w-[218px] text-0color text-[15px]">{t('role')}</span>
                <Controller
                  render={() => (
                    <div className="flex flex-col min-w-[130px] max-w-[130px]">
                      <div className="flex items-center gap-[10px]">
                        <input
                          name="c_role"
                          onChange={(e) => {
                            setValue('c_role', e.target.value);
                            clearErrors('c_role');
                          }}
                          type="checkbox"
                          value={'all'}
                          checked={watch('c_role') === 'all'}
                          className="focus:ring-offset-0 focus:ring-0 rounded-full"
                        />
                        <label className="text-[14px] text-0color">{t('all')}</label>
                      </div>
                      {chatRoles?.map((roleSettings) => {
                        return (
                          <div className="flex items-center gap-[10px]" key={roleSettings.crole_id}>
                            <input
                              name="c_role"
                              onChange={(e) => {
                                setValue('c_role', e.target.value);
                                clearErrors('c_role');
                              }}
                              type="checkbox"
                              checked={watch('c_role') === roleSettings.crole_id}
                              value={roleSettings.crole_id}
                              className="focus:ring-offset-0 focus:ring-0 rounded-full"
                            />
                            <label className="text-[14px] text-0color">{roleSettings.name}</label>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  name={'c_role'}
                  control={control}
                  rules={{ validate: (value) => !!value || tf('required') }}
                />
                {errors.c_role?.message && (
                  <div
                    className={
                      'absolute bottom-[-17px] right-[20px] text-sm text-red-600 dark:text-red-500'
                    }
                  >
                    {errors.c_role?.message}
                  </div>
                )}
              </div>
              <>
                <div className="flex items-center justify-between relative">
                  <span className="max-w-[218px] text-0color text-[15px]">{t('color')}</span>
                  <Controller
                    render={() => (
                      <div id="colors" className="flex items-center gap-[6px] mr-[23px]">
                        <ColorInput
                          onClick={(event) => {
                            clickColor(event);
                            clearErrors('activeColor');
                          }}
                          active={watch('activeColor')}
                        />
                      </div>
                    )}
                    name={'activeColor'}
                    control={control}
                    rules={{ validate: (value) => !!value || tf('required') }}
                  />
                  {errors.activeColor?.message && (
                    <div
                      className={
                        'absolute bottom-[-17px] right-[160px] text-sm text-red-600 dark:text-red-500'
                      }
                    >
                      {errors.activeColor?.message}
                    </div>
                  )}
                </div>
              </>
              {dataFields.map((e) => {
                return e.inputNumberChat.map((e, index) => {
                  return (
                    <div key={e.id} className="flex items-center justify-between">
                      <span className="text-0color max-w-[200px] text-[15px]">{e.labelTitle}</span>
                      <div className="bg-transparent w-[180px] mr-[101px]">
                        <Input
                          {...register(
                            e.id as
                              | 'exclude_begin_messages'
                              | 'exclude_end_messages'
                              | 'search_begin_messages'
                              | 'search_end_messages',
                          )}
                          max={1000000}
                          min={0}
                          name={e.id}
                          key={index}
                          type={'number'}
                          icon="ClockIcon"
                        />
                      </div>
                    </div>
                  );
                });
              })}
            </div>
          </div>
          <button type="submit" ref={buttonRef} className="hidden"></button>
        </form>
        <div className="flex flex-col gap-4 ">
          {/* TODO: */}
          <div className="flex flex-col gap-[20px]">
            <div className="flex items-center gap-[10px]">
              <Toggle
                onChecked={(e) => setValue('switchValue', e)}
                size={'large'}
                checked={watch('switchValue')}
              />
              <span className="-mt-[5px] text-0color text-[15px]">{t('all_words')}</span>
            </div>
            <WordListForFindMetric
              dictionaries={dictionaries}
              className="!w-[503px]"
              wordsListArray={watch('wordsListArray')}
              setWordsListArray={changeWordsArray}
              dictionariesListArray={watch('dictionariesListArray')}
              setDictionariesListArray={changeDictionariesArray}
            />
            <div className="border-b-solid border-b-[1px] border-b-[#DCE0E5] grid grid-rows-1 grid-cols-[1px_1fr_1px] pb-[16px]">
              <div className="w-[1px] h-[calc(100%+6px)] mt-[11px] bg-[#DCE0E5]" />

              <div>
                <div className="flex items-center pb-[16px]">
                  <div className="h-[1px] w-[110%] truncate bg-[#DCE0E5]"></div>
                  <span className="whitespace-nowrap text-0color text-[15px] px-2">
                    {t('relation_metrics')}
                  </span>
                  <div className="h-[1px] w-[110%] truncate bg-[#DCE0E5]"></div>
                </div>

                {!fields.length ? (
                  relationMetricIsEmptyMarkup
                ) : (
                  <div className="flex flex-col items-center">
                    {fields.map(({ id }, index) => {
                      return (
                        <div key={id} className="px-[10px]">
                          <div className="flex items-center w-full gap-[5px] ">
                            <div className="flex items-center gap-[10px]">
                              <div className="min-w-[120px] max-w-[120px]">
                                <HandleMetricSpecialToggle
                                  valuesList={[
                                    { title: t('before'), value: 'before' },
                                    { title: t('after'), value: 'after' },
                                  ]}
                                  isActive={watch(`relations.${index}.type`) || 'after'}
                                  onChange={(value: 'before' | 'after') => {
                                    setValue(`relations.${index}.type`, value);
                                    setValue(`relations.${index}.value`, 0);
                                  }}
                                />
                              </div>
                              <div className="max-w-[210px] min-w-[210px]">
                                <Select
                                  defaultValue={watch(`relations.${index}.metric_id`)}
                                  options={findWordsMetricsOptions || []}
                                  onChange={(value) =>
                                    setValue(`relations.${index}.metric_id`, String(value))
                                  }
                                />
                              </div>
                              <div className="min-w-[80px] max-w-[80px]">
                                <Input
                                  type="number"
                                  {...register(`relations.${index}.value`, {
                                    valueAsNumber: true,
                                    shouldUnregister: true,
                                    validate: (value) => value >= 1 || ' ',
                                  })}
                                  error={errors.relations?.[index]?.value?.message}
                                  min={0}
                                />
                              </div>
                            </div>
                            <div className="flex item-center gap-[0px]">
                              <CircleButton
                                size={13}
                                className="text-basic_red"
                                onClick={() => handleRemoveRelation(index)}
                                icon="TrashIcon"
                              />

                              <CircleButton
                                className={`text-action ${
                                  index === fields.length - 1 ? 'opacity-1' : 'opacity-0'
                                } transition-opacity`}
                                onClick={handleAppendRelation}
                                icon="PlusIcon"
                                size={13}
                              />
                            </div>
                          </div>
                          {fields.length > 1 && index !== fields.length - 1 && (
                            <div className="h-[17px] w-full flex items-center justify-center text-[14px] leading-[17px] font-[400] text-4color">
                              {t('or')}
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <div className="w-[1px] h-[calc(100%+6px)] mt-[11px] bg-[#DCE0E5]" />
            </div>
          </div>
          <AddToTemplateModal
            show={createTemplateModal}
            name={watch('name')}
            applyBtn={createMetricTemplateApplyBtn}
            onClose={setCreateTemplateModal}
          />
        </div>
      </div>
      <div className="sticky z-50 bottom-0 w-full">
        <div className="bg-[#fff] ">
          <div className="flex items-center justify-between flex-wrap py-4 w-full">
            <div className="flex items-center justify-between gap-[15px] py-4">
              <div className="gap-[15px] flex">
                <Button
                  disabled={(page !== 'metricTemplate' && disableFunc()) || viewOnly}
                  onClick={() => {
                    buttonRef.current?.click();
                  }}
                  label={t('save')}
                />
                <Button onClick={() => resetAsyncForm()} fill="linked" label={t('cancel')} />
              </div>
              <div>
                {page !== 'metricTemplate' && !createFromTemplate && (
                  <Button
                    onClick={() => setCreateTemplateModal(true)}
                    fill="linked"
                    icon="FolderConfigIcon"
                    label={t('save_as_template')}
                  />
                )}
              </div>
              <Button
                onClick={() => handleClearWordsComponent()}
                fill="linked"
                variant="danger"
                label={t('clear_all')}
              />
            </div>
            {editModal && (
              <div className="text-0color text-[15px] flex items-center">
                <span className="mb-[3px]">{t('metric_id')}</span>
                <span className="flex gap-1">
                  <p>
                    {metricTemplate
                      ? metricTemplateSettings?.metric_template_id
                      : metricSettings?.metric_id}
                  </p>
                  <TooltipWrapper content={t('popup_hints.copy_id')} id={'spech_metrick_copy'}>
                    <CircleButton
                      onClick={copyToClipboard}
                      icon="CopyIcon"
                      className="text-action mb-[5px]"
                      size={16}
                    />
                  </TooltipWrapper>
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

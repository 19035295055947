import {
  Button,
  EasyModal,
  Input,
  Modal,
  NumberSelect,
  Toggle,
  TooltipWrapper,
} from '@app/components/ui';
import useTranslation from '@app/hooks/use-translation';
import { DictionariesType } from '@app/interfaces/slices-types/dictionaries-slice.type';
import { navigationRoutes } from '@app/utils/navigation-routes';
import React, { ReactNode, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { NavLink } from 'react-router-dom';

import { v4 } from 'uuid';

import { FindWordsWordListType } from '@app/interfaces/pages-types/anatylics-metric.type';

import _ from 'lodash';

import { XIcon, SettingIcon } from '../ui/icons/icons-list';
import Icon from '../ui/icons';

type WordsListComponentPropsType = {
  currentDictionaryId?: string | undefined;
  wordsListArray: FindWordsWordListType[];
  setWordsListArray(word: FindWordsWordListType[]): void;
  dictionariesListArray: string[];
  setDictionariesListArray(word: string[]): void;
  className?: string;
  dictionaries?: DictionariesType[];
  variant?: 'wordsCloud' | 'default';
  withoutLabel?: boolean;
  scroll?: boolean;
  additionalMarkup?: ReactNode;
};
export const WordListForFindMetric: React.FC<WordsListComponentPropsType> = React.memo((props) => {
  const {
    wordsListArray,
    setWordsListArray,
    className,
    setDictionariesListArray,
    dictionariesListArray,
    currentDictionaryId,
    dictionaries,
    variant = 'default',
    additionalMarkup,
    withoutLabel = false,
    scroll = false,
  } = props;
  const { t } = useTranslation('components.wordListComponent');
  const [dictionariesModal, setDictionariesModal] = React.useState(false);
  const [inputVisible, setInputVisible] = React.useState(false);
  const [deleteVisible, setDeleteVisible] = React.useState(false);
  const [wordsListInput, setWordsListInput] = React.useState('');
  const [activeWord, setActiveWord] = React.useState('');
  const [wordToEdit, setWordToEdit] = useState<FindWordsWordListType>();
  const handleKeyDown = (e) => {
    if (e.target.id === 'mainContainer') {
      setInputVisible(true);
    }
  };
  React.useEffect(() => {
    setTimeout(() => {
      if (inputVisible && inputRef.current) {
        inputRef.current?.focus();
      }
    }, 0);
  }, [inputVisible]);
  // add word
  function splitWordToSentenceArr(word: string): Array<{
    id: number;
    word: string;
    distance?: number;
  }> {
    const splitWord = word.replace(/ +(?= )/g, '').split(' ');
    return splitWord.map((spitedWord, index) =>
      index === splitWord.length - 1
        ? { id: index + 1, word: spitedWord, distance: undefined }
        : { distance: 0, id: index + 1, word: spitedWord },
    );
  }

  function handleAddNewWord() {
    if (wordsListInput === '') return;
    if (!wordsListArray.some(({ full_word }) => full_word === wordsListInput)) {
      const newWordObj: FindWordsWordListType = {
        full_word: wordsListInput.replace(/ +(?= )/g, ''),
        id: v4(),
        words_order: true,
        sentence: splitWordToSentenceArr(wordsListInput),
      };
      setWordsListArray([...wordsListArray, newWordObj]);
      setWordsListInput('');
    } else {
      toast.error(t('word_err'));
    }
  }
  console.log(wordsListArray);

  const handleOnBlurInput = () => {
    handleAddNewWord();
    setInputVisible(false);
  };

  const setNewWord = (event) => {
    if (event.key !== 'Enter') return;
    handleAddNewWord();
  };

  const setNewDictionary = (id: string) => {
    if (!dictionariesListArray.includes(id)) {
      setDictionariesListArray([...dictionariesListArray, id]);
      setDictionariesModal(false);
    } else {
      toast.error(t('dict_err'));
    }
  };

  const deleteWord = (wordId: string) => {
    setWordsListArray(wordsListArray?.filter((words) => words.id !== wordId));
  };

  const editWord = (wordObj: FindWordsWordListType) => {
    setWordToEdit(wordObj);
  };
  function handleCloseEditModal() {
    setWordToEdit(undefined);
  }

  const deleteDictionary = (word: string) => {
    setDictionariesListArray(dictionariesListArray?.filter((e) => e !== word));
  };

  const onClickWord = (word: string) => {
    setDeleteVisible(true);
    setActiveWord(word);
  };

  const inputRef = React.useRef<HTMLInputElement>(null);

  const getDictionaryNameById = (id: string) => {
    return dictionaries?.find((e) => e.dictionary_id === id)?.name;
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    const data = e.clipboardData.getData('text/html');
    const parser = new DOMParser()
      .parseFromString(data, 'text/html')
      .body.getElementsByTagName('p');
    if (parser.length >= 2) {
      const arr: string[] = [];
      for (let i = 0; i < parser.length; i++) {
        arr.push(parser?.item(i)?.innerText.trim() as string);
      }

      const array = [
        ...wordsListArray,
        ..._.uniq(arr)
          .filter((word) => !wordsListArray.find((old) => old.full_word === word))
          .map((word) => {
            const newWordObj: FindWordsWordListType = {
              full_word: word,
              // .slice(0, 127)
              id: v4(),
              words_order: true,
              sentence: splitWordToSentenceArr(word),
            };
            return newWordObj;
          }),
      ];

      setWordsListArray(array);
      setInputVisible(false);
      setWordsListInput('');
    }
  };

  function handleChangeSentenceInput({ value, wordId }: { value: number; wordId: number }) {
    setWordToEdit((prev) => {
      if (!prev) return;
      const formattedSentence = prev.sentence.map((sentence) => {
        if (sentence.id === wordId) {
          return { ...sentence, distance: value };
        }
        return sentence;
      });
      return { ...prev, sentence: formattedSentence };
    });
  }
  function handleApplyWordEditedSettings() {
    const formattedWordList = wordsListArray.map((word) => {
      if (word.id === wordToEdit?.id) {
        return wordToEdit;
      }
      return word;
    });
    setWordsListArray(formattedWordList);
    handleCloseEditModal();
  }
  console.log(wordToEdit, 'wordToEdit');

  function handleResetWordsSettings() {
    // const formattedWordList = wordsListArray.map((word) => {
    //   if (word.id === wordToEdit?.id) {
    //     return {
    //       ...word,
    //       sentence: word.sentence.map((sent, index) =>
    //         word.sentence.length - 1 === index
    //           ? { ...sent, distance: undefined }
    //           : { ...sent, distance: 0 },
    //       ),
    //     };
    //   }
    //   return word;
    // });
    setWordToEdit((prev) => {
      if (!prev) return;
      return {
        ...prev,
        sentence: prev.sentence.map((sent, index) =>
          prev.sentence.length - 1 === index
            ? { ...sent, distance: undefined }
            : { ...sent, distance: 0 },
        ),
      };
    });
    // setWordsListArray(formattedWordList);
  }
  useEffect(() => {
    if (!wordToEdit) return;
    if (wordToEdit.words_order) return;
    const condition = wordToEdit?.sentence.some((sent) => (sent?.distance as number) > 0);
    if (!condition) return;
    setWordToEdit((prev) => {
      if (!prev) return;
      return {
        ...prev,
        words_order: true,
      };
    });
  }, [wordToEdit]);
  return (
    <div className="flex justify-start flex-col">
      {!withoutLabel && (
        <div className="mb-[12px] flex items-center justify-between">
          <span className="text-0color text-[15px]">{t('word_for_search')}</span>
        </div>
      )}
      <div
        id="mainContainer"
        onMouseDown={(e) => handleKeyDown(e)}
        className={`${className || ''} ${
          scroll ? 'overflow-y-scroll' : 'truncate'
        } w-full min-h-[360px] flex items-start border-[#DCE0E5] border-solid border-[1px] p-[10px_28px_10px_10px] rounded-[10px]`}
      >
        <div id="mainContainer" className="flex flex-wrap items-center gap-[6px] w-full ">
          {dictionariesListArray.map((dictionary, index) => {
            return (
              <div
                className="flex truncate items-center p-[2px_16px_3px] relative bg-[#FFE2F4] text-0color underline text-[13px] rounded-[4px] gap-[4px] z-10"
                onMouseMove={() => onClickWord(dictionary)}
                onMouseOut={() => setDeleteVisible(false)}
                key={index}
              >
                <NavLink
                  to={`/${navigationRoutes.dictionaries}/${dictionary}/edit`}
                  target={'_blank'}
                  className="cursor-pointer truncate"
                >
                  <TooltipWrapper
                    className="flex relative"
                    content={t('popup_hints.to_dictionary')}
                    id={v4()}
                  >
                    <span className="truncate">{getDictionaryNameById(dictionary)}</span>
                  </TooltipWrapper>
                </NavLink>
                {deleteVisible && activeWord === dictionary && (
                  <div
                    onClick={() => deleteDictionary(dictionary)}
                    className="bg-[#fff] p-[1.5px_2px] absolute right-[3px] rounded-full flex items-center justify-center"
                  >
                    <XIcon
                      hintTitle={t('popup_hints.delete_icon')}
                      className="text-basic_red cursor-pointer"
                      size={7}
                    />
                  </div>
                )}
              </div>
            );
          })}

          {wordsListArray.map((word, index) => {
            return (
              <div
                onMouseMove={() => !inputVisible && onClickWord(word.full_word)}
                onMouseOut={() => !inputVisible && setDeleteVisible(false)}
                key={index}
                className={`p-[2px_29px_3px_24px] ${
                  !inputVisible && word.sentence.length > 1 && ''
                }  transition-all duration-300 relative flex items-center gap-[4px] bg-defaultBg text-0color text-[13px] rounded-[4px] max-w-full first:max-w-[calc(100%-50px)]`}
              >
                {!word.words_order && (
                  <Icon
                    size={11}
                    className="p-[1px_2px] bg-white rounded-[3px]"
                    name="RefreshIcon"
                  />
                )}
                <div className="truncate flex items-center gap-[5px]">
                  {word.sentence.map((wordS, index) => {
                    return (
                      <span
                        key={wordS.word + index}
                        className={`relative ${wordS.distance && 'pr-[5.5px]'}`}
                      >
                        {word.words_order ? wordS.word : index <= 4 ? wordS.word : ''}
                        {word.words_order && index !== word.sentence.length - 1 && (
                          <span className="text-[8px] right-[-5.5px] absolute top-0 text-1color p-[0px_3px] bg-white rounded-[3px]">
                            {wordS.distance || ''}
                          </span>
                        )}
                      </span>
                    );
                  })}
                </div>

                <div
                  className={`${
                    deleteVisible && activeWord === word.full_word ? 'opacity-1 ' : 'opacity-0'
                  } flex transition-opacity items-baseline gap-[3px] z-10 absolute top-[8px] right-[2px]`}
                >
                  {word.sentence.length > 1 && (
                    <div
                      onClick={() =>
                        word.sentence.length > 1 &&
                        editWord({
                          ...word,
                          sentence: word.full_word.split(' ').map((wordSplit, index) => {
                            const wordIntoSentence = word.sentence.find(
                              (sent) => sent.word === wordSplit && sent.id === index + 1,
                            );
                            return {
                              word: wordSplit,
                              id: wordIntoSentence?.id || index + 1,
                              distance: wordIntoSentence?.distance || 0,
                            };
                          }),
                        })
                      }
                      className="bg-[#fff] p-[1.5px_2px] rounded-full flex items-center justify-center"
                    >
                      <TooltipWrapper content={t('popup_hints.edit_icon')} id={v4() + '_edit'}>
                        <SettingIcon
                          className={`${
                            word.sentence.length > 1
                              ? 'text-action cursor-pointer'
                              : 'text-3color cursor-default'
                          } `}
                          size={7}
                        />
                      </TooltipWrapper>
                    </div>
                  )}

                  <div
                    onClick={() => deleteWord(word.id)}
                    className="bg-[#fff] p-[1.5px_2px] rounded-full flex items-center justify-center"
                  >
                    <TooltipWrapper content={t('popup_hints.delete_icon')} id={v4() + 'delete'}>
                      <XIcon className="text-basic_red cursor-pointer " size={7} />
                    </TooltipWrapper>
                  </div>
                </div>

                {/* )} */}
              </div>
            );
          })}

          {inputVisible && (
            <div className="flex items-center gap-[6px]">
              <div
                tabIndex={-1}
                onBlur={handleOnBlurInput}
                onKeyDown={(event) => setNewWord(event)}
              >
                <Input
                  ref={inputRef}
                  defaultValue={wordsListInput}
                  onChange={(e) => setWordsListInput(e.target.value)}
                  maxLength={128}
                  onPaste={handlePaste}
                  type={'text'}
                  name={'wordList'}
                />
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col  ml-auto items-end relative">
          {variant !== 'wordsCloud' && (
            <div onClick={() => setDictionariesModal(true)} className="absolute ">
              <Button
                hintTitle={t('popup_hints.add_dictionary_from_list')}
                fill="linked"
                label="+"
                icon={'BookIcon'}
                className={'!px-[3px]'}
              />
            </div>
          )}
          {additionalMarkup ? additionalMarkup : <></>}
        </div>
      </div>
      <Modal
        id={'Dictionaries'}
        value={dictionariesModal}
        setValue={setDictionariesModal}
        title={<div className="w-full flex justify-center">{t('dict')}</div>}
        noFooter
        size="md"
      >
        {
          <ul className="flex rounded-[12px] overflow-x-hidden flex-col border-[1px] border-[#F5F5F7]">
            {dictionaries
              ?.filter((e) => e.dictionary_id !== currentDictionaryId)
              .map((type) => {
                return (
                  <li
                    className="w-full items-center justify-between flex border-b-[1px] border-[#F5F5F7] p-[10px] text-1color text-[17px] h-[64px]"
                    key={type.dictionary_id}
                  >
                    <button
                      onClick={() => setNewDictionary(type.dictionary_id)}
                      type="button"
                      data-id={`dictionary-list-${type.name}`}
                      className={`flex w-full items-center justify-between`}
                    >
                      <div className="truncate">{type.name}</div>
                      <span className="w-[28px] text-[#1C1C1E] bold">&gt;</span>
                    </button>
                  </li>
                );
              })}
          </ul>
        }
      </Modal>
      {/* edit metric modal */}
      <EasyModal
        variant="mediumW400"
        show={!!wordToEdit}
        label={t('phrase_settings')}
        withoutFooter
        withoutContentPadding
        onClose={handleCloseEditModal}
      >
        {/* rounded-r-2xl border-r-solid border-r-black border-r-[1px] */}
        <div className="p-[24px_24px_0px_24px]">
          <div className="pb-[20px] flex flex-col gap-[15px]">
            <div className="flex items-center self-start text-[12px] gap-[10px] text-3color">
              <Toggle
                checked={!wordToEdit?.words_order}
                onChecked={(val) => {
                  setWordToEdit((prev) => {
                    if (!prev) return;
                    return { ...prev, words_order: !val };
                  });
                }}
                size="small"
                disabled={wordToEdit?.sentence.some((sent) => (sent?.distance as number) > 0)}
              />
              <span>{t('any_order')}</span>
            </div>
            <div className="grid grid-rows-1 grid-cols-[1px_1fr_1px] w-full h-full relative overflow-hidden">
              <div className="w-[1px] h-[calc(100%-23px)] mt-[11.5px] bg-3color"></div>
              <div className={`flex flex-col items-center gap-[10px] grow`}>
                {(wordToEdit?.words_order
                  ? wordToEdit?.sentence
                  : wordToEdit?.sentence.slice(0, 5)
                )?.map((sentence, index) => {
                  const lastWord =
                    ((wordToEdit?.words_order
                      ? wordToEdit?.sentence
                      : wordToEdit?.sentence.slice(0, 5)
                    )?.length || 0) -
                      1 ===
                    index;
                  return (
                    <div
                      key={sentence.word + '_' + sentence.id}
                      className={`flex flex-col items-center gap-[10px] w-full`}
                    >
                      <div className="flex items-center w-full justify-center ">
                        {(index === 0 || lastWord) && (
                          <div className="h-[1px] w-[110%] truncate bg-black"></div>
                        )}
                        <span
                          title={sentence.word}
                          className={`text-[16px] text-center w-full text-3color max-w-[120px] px-2 truncate block`}
                        >
                          {sentence.word.replace(/[^%a-zA-ZА-Яа-яёЁ0-9]/g, '')}
                        </span>
                        {(index === 0 || lastWord) && (
                          <div className="h-[1px] w-[110%] truncate bg-black"></div>
                        )}
                      </div>

                      {!lastWord && (
                        <div className="">
                          <NumberSelect
                            range={6}
                            activeNumber={sentence.distance}
                            onChange={function (number: number): void {
                              handleChangeSentenceInput({ value: number, wordId: sentence.id });
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="w-[1px] h-[calc(100%-23px)] mt-[11.5px] bg-3color"></div>
            </div>
          </div>
          <div className="flex items-center justify-between py-[20px] sticky bottom-0 bg-white">
            <div>
              <Button onClick={handleApplyWordEditedSettings} label={t('save')} />
              <Button
                onClick={handleResetWordsSettings}
                label={t('reset')}
                fill="linked"
                variant="danger"
              />
            </div>
            <Button onClick={handleCloseEditModal} fill="linked" label={t('cancel')} />
          </div>
        </div>
      </EasyModal>
      {/* edit metric modal */}
    </div>
  );
});
WordListForFindMetric.displayName = 'WordListForFindMetric';

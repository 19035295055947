import { ReportChart, ReportChatSeries } from '@app/interfaces/report.type';
import ReactDOMServer from 'react-dom/server';

import { ApexOptions } from 'apexcharts';
import { DownloadCloudIcon } from '@icons';
import React from 'react';

// const DEFAULT_BAR_WIDTH = 75;
const DEFAULT_BORDER_RADIUS = 5;
const DEFAULT_TRANSLATION_LIST = {
  download: 'Скачать SVG',
  selection: 'Выбрать',
  selectionZoom: 'Увеличить выбранное',
  zoomIn: 'Увеличить',
  zoomOut: 'Уменьшить',
  pan: 'Переместить',
  reset: 'Сбросить настройки',
};
const roundClosestLegendNumber = function (number: number) {
  if (number < 10) {
    return Math.ceil(number);
  }

  let multiplier = 10;
  while (number >= 10 * multiplier) {
    multiplier = 10 * multiplier;
  }

  let count = 1;
  while (number > multiplier * count) {
    count++;
  }

  return multiplier * count;
};
const downloadChartMenu = (title?: string) =>
  ReactDOMServer.renderToString(
    <div title="" data-title={title} className="mt-[2px] ml-[3px] customTitleStyles">
      <DownloadCloudIcon
        className="text-3color active:scale-[90%] z-[0] hover:opacity-80"
        size={22}
      />
    </div>,
  );

const seriesCalculator = (chart: ReportChart) => {
  if (!chart?.series) return [];
  switch (chart.type) {
    case 'piechart':
      return chart?.series.map((series) => series.data.map((item) => item))[0];
    case 'linechart':
      return chart?.series?.map((seriesItem) => ({
        name: seriesItem.name,
        data: seriesItem?.data,
      }));
    case 'horizontal_barchart':
      return chart?.series?.map((seriesItem) => ({
        name: seriesItem.name,
        data: seriesItem.data,
      }));
    default: {
      return chart?.series?.map((seriesItem) => ({
        name: seriesItem.name,
        data: seriesItem.data,
      }));
    }
  }
};

function legendMarkersColors(series: ReportChatSeries[], isPie: boolean) {
  const result = series.map(({ color }) => color).flat(1);
  return isPie
    ? Array(Math.floor(series[0].data.length / result.length))
        .fill(result)
        .flat(1)
    : result;
}

const chartLabelTypeByChartType = (chart: ReportChart) => {
  if (chart.type === 'piechart') {
    return chart?.categories?.length ? chart?.categories : [];
  }
  return chart?.series?.length ? chart?.series?.map((seriesItem) => seriesItem.name) : [];
};
const chartColorByChartType = (chart: ReportChart) => {
  if (chart.type === 'piechart') {
    return chart.series[0].color.map((item) => item);
  }
  return chart.series?.length
    ? chart.series.map((seriesItem) => {
        if (seriesItem.conditional_formatting?.length !== 0) {
          return ({ value }) => {
            const conditionalColor = seriesItem.conditional_formatting?.reduce<string | undefined>(
              (lastValue, currentValue) => {
                if (lastValue === undefined) {
                  if (value >= currentValue.value[0] && value <= currentValue.value[1])
                    return currentValue.color;
                }
                return lastValue;
              },
              undefined,
            );
            if (conditionalColor) return conditionalColor;
            else {
              return chart.series.length ? seriesItem.color[0] : '#000';
            }
          };
        } else {
          return seriesItem.color[0];
        }
      })
    : [];
};
const chartAnnotationByChartType = (chart: ReportChart) => {
  if (!chart?.line_settings?.active) return {};
  if (chart?.type === 'horizontal_barchart') {
    return {
      xaxis: [
        {
          x: chart.line_settings.value[0],
          x2: chart.line_settings.value?.[1],
          borderColor: chart.line_settings.color,
          fillColor: chart.line_settings.color,
          strokeDashArray: 0,
          opacity: 0.05,
        },
      ],
    };
  } else {
    return {
      yaxis: [
        {
          y: chart.line_settings.value[0],
          y2: chart.line_settings.value?.[1],
          borderColor: chart.line_settings.color,
          fillColor: chart.line_settings.color,
          offsetX: -1000,
          width: '100000%',
          opacity: 0.05,
          strokeDashArray: 0,
        },
      ],
    };
  }
};
function themeForPieChart(chart: ReportChart) {
  if (chart.type === 'piechart') {
    return {
      theme: {
        palette: chart.series.length ? chart.series[0].color : 'palette1',
      },
    };
  }
}
function getStrokeSettingsByChartType(chart: ReportChart) {
  if (chart.type !== 'linechart') {
    return { colors: ['transparent'], width: 4 };
  }
}
const selectChartType = (type?: ReportChart['type']) => {
  const DEFAULT_CHART: ReportChart['type'] = 'barchart';
  const chartSettings: Record<
    ReportChart['type'],
    {
      chart: ApexOptions['chart'];
      plotOptions?: ApexOptions['plotOptions'];
    }
  > = {
    piechart: { chart: { type: 'pie' } },
    number: { chart: { type: 'line' } },
    linechart: { chart: { type: 'line' } },
    columnchart: { chart: { type: 'bar', stacked: true } },
    horizontal_barchart: { chart: { type: 'bar' }, plotOptions: { bar: { horizontal: true } } },
    barchart: { chart: { type: 'bar' }, plotOptions: { bar: { horizontal: false } } },
  };
  return type ? chartSettings[type] || chartSettings[DEFAULT_CHART] : chartSettings[DEFAULT_CHART];
};
export const chartBuildData = ({
  chart,
  translationList,
  events,
  showToolbar,
  chartWidth,
  noDataText,
  tooltipDynamicOffset,
  tooltipCustomOffset,
}: {
  chart: ReportChart;
  translationList?: Partial<typeof DEFAULT_TRANSLATION_LIST>;
  isDashboard?: boolean;
  events?: any;
  showToolbar?: boolean;
  chartWidth?: number;
  tooltipDynamicOffset?: number;
  tooltipCustomOffset?: number;
  noDataText?: { text: string };
}) => {
  return {
    options: {
      tooltip: {
        enabled: true,
        y: {
          formatter: function (value) {
            return value;
          },
        },
        style: {
          dropShadow: {
            enabled: false,
          },
          opacity: 0.4,
          background: {
            enabled: false,
            dropShadow: {
              enabled: false,
            },
          },
        },
      },
      labels: chartLabelTypeByChartType(chart),
      grid: {
        show: chart.styles?.grid,
        strokeDashArray: 3.3,
        position: 'back',
        borderColor: '#E5E8EB',
        xaxis: { lines: { show: chart.type === 'horizontal_barchart' } },
        yaxis: { lines: { show: chart.type !== 'horizontal_barchart' } },
      },
      stroke: {
        ...getStrokeSettingsByChartType(chart),
        curve: 'smooth',
      },
      yaxis: {
        forceNiceScale: true,
        decimalsInFloat: 0,
        min: chart.yaxis_min_value ? undefined : 0,
        max: function (max: number) {
          const percents = Math.round(max) * 0.12;
          return roundClosestLegendNumber(max + percents);
        },
        // labels: {
        //   formatter: (val) => Math.round(val),
        // },
      },
      ...themeForPieChart(chart),
      chart: {
        zoom: {
          allowMouseWheelZoom: false,
        },
        redrawOnParentResize: true,
        id: `chart_${chart.graph_id}`,
        ...selectChartType(chart.type).chart,
        offsetX: 10,
        animations: {
          enabled: false,
        },
        events: events || {},
        toolbar: {
          show: chartWidth ? (chartWidth || 0) > 260 : true,
          offsetX: tooltipDynamicOffset,
          offsetY: tooltipCustomOffset ? tooltipCustomOffset : -49,
          tools: {
            download: downloadChartMenu((translationList || DEFAULT_TRANSLATION_LIST)['download']),
            pan: showToolbar,
            zoom: showToolbar,
            selection: showToolbar,
            zoomin: showToolbar,
            zoomout: showToolbar,
            reset: showToolbar,
          },
        },
        defaultLocale: 'ru',
        locales: [
          {
            name: 'ru',
            options: {
              toolbar: { ...DEFAULT_TRANSLATION_LIST, ...translationList },
            },
          },
        ],
      },
      dropShadow: {
        enabled: false,
      },
      dataLabels: {
        enabled: chart.display_data,
        formatter: function (val) {
          if (chart.type === 'piechart') {
            return Math.ceil(Number(val)) + '%';
          } else if (!chart.nullable_values) {
            if (val !== 0) return val;
          } else return val;
        },
        offsetY:
          chart.styles?.value_position === 'inside'
            ? 0
            : chart.type === 'barchart' || chart.type === 'columnchart'
            ? -25
            : chart.type === 'horizontal_barchart'
            ? -2
            : 0,
        offsetX:
          chart.type === 'horizontal_barchart'
            ? chart.styles.value_position === 'inside'
              ? 0
              : 30
            : 0,
        style: {
          fontSize: `${chart.styles?.text_size || 12}px`,
          colors: chart.styles?.text_color ? [chart.styles?.text_color] : ['rgb(114, 114, 114)'],
          zIndex: 0,
        },
        dropShadow: {
          enabled: false,
        },
      },
      colors: chartColorByChartType(chart),
      plotOptions: {
        bar: {
          borderRadiusApplication: 'end',
          borderRadius: DEFAULT_BORDER_RADIUS,
          columnWidth: `100%`,
          dataLabels: {
            position: 'top',
          },
          horizontal: false,
          ...(selectChartType(chart.type)?.plotOptions?.bar || {}),
        },
      },
      xaxis: {
        categories: chart?.categories?.length ? chart?.categories : [],
        tickPlacement: 'on',
        max: !chart.max_group_display ? undefined : chart.max_group_display,
        labels: {
          rotate: -45,
          // rotateAlways: true,
          trim: chart.type !== 'horizontal_barchart',
          hideOverlappingLabels: false,
        },
      },
      noData: noDataText,
      legend: {
        position: (chartWidth || 0) > 500 ? chart.styles.legend_position : 'bottom',
        markers: {
          fillColors: chart.series?.length
            ? legendMarkersColors(chart.series, chart.type === 'piechart')
            : [],
        },
        // position: chart.styles?.legend_position,
        horizontalAlign: 'center',
        show: chart.display_legend,
        showForSingleSeries: chart.display_legend,
        showForNullSeries: chart.display_legend,
        showForZeroSeries: chart.display_legend,
        // offsetY: chart.styles.legend_position !== 'bottom' ? 140 : 0,
      },
      annotations: chartAnnotationByChartType(chart),
    },

    series: seriesCalculator(chart),
  };
};
